import './App.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Home from './Views/Home';
import { Route, Routes, Navigate , useLocation} from 'react-router-dom';
import {Fade , Slide , } from "react-awesome-reveal";
import Gallery from './Views/Gallery';
function App() {
  return (
    <Fade delay={100}>
      <main className='bg-black'>
        <Navbar />
        <Routes>
          <Route path='/' element = {<Home />} />
          <Route path='/Gallery' element = {<Gallery />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </main>
    </Fade>
  );
}

export default App;
