const Data = [
    {
        id: 1,
        name: 'Tacos',
        options: [
            'Al pastor (BBQ Pork)', 'Asada (Steak)'
         ],
        img: require('../img/1.png')
    },
    {
        id: 2,
        name: 'Nachos',
        options: [
            'Al pastor (BBQ Pork)', 'Asada (Steak)'
        ],
        img: require('../img/3.png')
    },
    {
        id: 3,
        name: 'Burritos',
        options: [
            'Al pastor (BBQ Pork)', 'Asada (Steak)'
        ],
        img: require('../img/8.png')
    },
    {
        id: 4,
        name: 'Quesadillas',
        options: [
            'Al pastor (BBQ Pork)', 'Asada (Steak)'
        ],
        img: require('../img/7.png')
    },
    {
        id: 5,
        name: 'Churros',
        options: [],
        img: require('../img/4.png')
    },
    {
        id: 6,
        name: 'Churros Rellenos (Filled Churros)',
        options: [
            'Fresa (Strawberry)', 'Vainilla (Vanilla)', 'Cajeta (Caramel)', 'Chocolate' 
        ],
        img: require('../img/6.png')
    },
    {
        id: 7,
        name: 'Aguas Frescas',
        options: [
            'Pineapple' , 'Horchata' , 'Jamaica' , 'Lemon (Límon)'
        ],
        img: require('../img/2.png')
    },
    {
        id: 8,
        name: 'Nieves (Ice Cream)',
        options: [
            'Strawberry (Fresca)' , 'Pecan (nuez)' , 'Coconut' , 'Mango' , 'Pineapple' , 'Lemon (Límon)'
        ],
        img: require('../img/5.png')
    }
];

export default Data;