import {Swiper , SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Autoplay, FreeMode} from "swiper/modules";

export default function SwiperPro(props) {

    return (
        <Swiper
            loop = {true}
            breakpoints={{
                340: {
                    slidesPerView:1,
                },
                700: {
                    slidesPerView: 3,
                    spaceBetween: 15
                }
            }}
            autoplay = {{
                delay: 2500,
                disableOnInteraction: false,
            }}
            pagination = {{
                clickable: true
            }}
            modules={[Autoplay , FreeMode]}
        >
            {props.serdata.map((s , index) => (
                <SwiperSlide key={index}>
                    <div className="lg:p-4 cursor-pointer text-main-color">
                        <div className=" flex sticky lg:hidden">
                            <img alt="#" className=" rounded-lg" src={s.img} />

                            <div className=" absolute p-1 inset-0 z-10 flex flex-col justify-end opacity-100">
                                <div className="ml-3 text-xl text-center bg-black/40">
                                    <h1>{s.name}</h1>
                                </div>
                                <div className="ml-3 text-xl text-center text-white bg-black/40">
                                    <h1>{s.type}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="sticky lg:block hidden">
                            <img alt="#" className="rounded-lg" src={s.img} />
                            <div className=" absolute p-8 inset-0 z-10 bg-black group flex flex-col justify-end opacity-0 hover:opacity-100 ease-linear duration-500 transition-all bg-opacity-50">
                                <div className="ml-3 text-2xl">
                                    <h1>{s.name}</h1>
                                </div>
                                <div className=' ml-4 text-xl group-hover:text-white text-black'>
                                    <span>{s.type}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};