import React, { useEffect, useState } from "react";
// import video1 from "../img/video1.mp4";
import Data from "../Components/Data"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import SwiperPro from "../Components/SwiperPro";
import {Fade , Slide , JackInTheBox} from "react-awesome-reveal";

import ice1 from "../img/icecream/1.png";
import ice2 from "../img/icecream/2.png";
import ice3 from "../img/icecream/3.png";
import ice4 from "../img/icecream/4.png";
import ice5 from "../img/icecream/5.png";
import ice6 from "../img/icecream/6.png";

import img1 from "../img/img2.png";
import img2 from "../img/img3.png";
import img3 from "../img/img4.png";
import img4 from "../img/img5.png";

import store from "../img/img16.png";
import store1 from "../img/img14.jpg";
import store2 from "../img/img15.jpg";
import gato from "../img/gato.png";

function importImg(r){
    return r.keys().map(r);
}
export default function Home (){
    const [isPop , SetisPop] = useState(false);
    const [isdata , setisdata] = useState([]);
    const [id , setId] = useState('');    
    const handleClick = (da , name) => {
        SetisPop(!isPop);
        setisdata(da);
        setId(name);
    };
	const fnames = importImg(require.context('../img/food/' , false , /\.(png|jpg|jpeg)$/));
    
    const SerData = [
        {
            img: img1,
            name: "Vanilla (Vainilla)"
        },
        {
            img: img2,
            name: "Strawberry (Fresca)"
        },
        {
            img: img3,
            name: "Chocolate"
        },
        {
            img: img4,
            name: "Caramel (Cajeta)"
        }
    ];

    const IceData = [
        {
            img: ice1,
            name: 'Milk Based',
            type: 'Strawberry'
        },
        {
            img: ice2,
            name: 'Milk Based',
            type: 'Pecan',
        },
        {
            img: ice3,
            name: 'Milk Based',
            type: 'Coconut'
        },
        {
            img: ice4,
            name: 'Water Based',
            type: 'Mango'
        },
        {
            img: ice5,
            name: 'Water Based',
            type: 'Pineapples'
        },
        {
            img: ice6,
            name: 'Water Based',
            type: 'Lemon'
        }
    ]

    return(
        <>
            {isPop && id !== 'Churros'? 
                (
                    <div onClick={() => SetisPop(!isPop)} className=" px-2 fixed overflow-x-hidden left-0 top-0 inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-60">
                        <div className='relative'>
                            <div className=" bg-black text-white w-[800px] h-[400px] lg:h-[500px] shadow-lg rounded-lg">
                                <div className="flex space-y-6 lg:space-y-4 flex-col p-4 lg:p-12 w-full h-full">
                                    <div>
                                        <div className=" flex flex-col text-center">
                                            <h1 className=" font-Roboto text-main-color text-xl lg:text-4xl ">{id}:</h1>
                                            {(id === 'Nieves (Ice Cream)' || id === 'Aguas Frescas') && (
                                                <h1 className=" font-Roboto text-red-600 text-lg ">
                                                    * these will change everyday
                                                </h1>
                                            )}
                                        </div>
                                        <hr className=" hidden lg:block"></hr>
                                    </div>
                                    <div  className=" flex flex-col space-y-2 lg:space-y-4 lg:justify-center items-center h-full">
                                        {isdata.map((da , id) => (
                                            <ul key = {id} className=" list-inside text-lg lg:text-3xl">
                                                <li>{da}</li>
                                            </ul>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className=' absolute top-0 right-0'>
                                <button className = " lg:p-4" onClick={() => SetisPop(!isPop)}>
                                    <FontAwesomeIcon className=' text-3xl text-main-color' icon={faXmark} />
                                </button>
                            </div>
                        </div>
                    </div>
                ) :null
            }
            
            <section className="relative font-Lora overflow-x-hidden">
                <div className="w-screen bg-pagel bg-no-repeat bg-center bg-cover h-screen relative">
                    <div className="w-screen absolute bg-black bg-opacity-45 flex justify-center items-center flex-col h-screen text-center px-3 py-5 space-y-8 lg:py-8 md:space-y-12">
                        <div className=" space-y-4">
                            <h1 className="text-4xl capitalize ml-4 text-white md:text-7xl tracking-wide">
                                Welcome To
                            </h1>
                            <h1 className="text-4xl italic uppercase ml-4 text-main-color md:text-8xl tracking-widest font-thin">
                                El Cachorro
                            </h1>
                        </div>
                        <div className="">
                            <button onClick={() => {
                                window.location.href = 'tel:2092857859'
                            }} className=" py-3 transition ease-in-out hover:bg-white/20 duration-200 delay-100 bg-main-color w-[250px]">
                                <span className=" transition ease-in-out duration-200 delay-100 text-xl text-white">
                                    Contact Us
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <Fade>
                    <div className=" mt-8 pb-14 p-4 mx-auto max-w-screen-xl">
                        <div className="grid gap-4 lg:gap-8 lg:grid-cols-3">
                            {Data.map((d) => (
                                <div key={d.id} className="cursor-pointer rounded-lg ease-in-out transition-all">
                                    <div className="sticky h-full">
                                        {d.name !== 'Churros'? (
                                            <>
                                                <img onClick={() => handleClick(d.options , d.name)} className=" w-full rounded-lg" alt="#" src={d.img} />
                                                <div onClick={() => handleClick(d.options , d.name)} className=" hidden absolute p-8 inset-0 z-10 bg-black lg:flex flex-col justify-center opacity-0 hover:opacity-100 ease-linear duration-500 transition-all bg-opacity-50">                                                            
                                                    <div className=" bg-black/20 p-1 lg:p-4">
                                                        <div className=" flex justify-center text-lg text-white font-thin space-x-1">
                                                            <span className="">View Options</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ): (
                                            <div className="shadow-lg overflow-hidden rounded-lg">
                                                <img className="w-full ease-in-out transition-all duration-1000 hover:scale-125" src={d.img}/>
                                            </div>
                                        )}
                                        <div className="text-center text-white px-6 py-4">
                                            <h1 className=" border-b-[1px] border-main-color text-lg md:text-3xl capitalize font-lato whitespace-nowrap">{d.name}</h1>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className="pb-8">
                        <div className="lg:p-8 p-4 mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                            <div className="flex flex-col">
                                <span className="text-center text-2xl italic lg:text-5xl text-white capitalize">
                                    Churros Flavor (Sabores)
                                </span>
                            </div>
                            <SwiperPro serdata = {SerData} />
                        </div>
                    </div>
                </Fade>
                <Fade>
                    <div className="pb-8">
                        <div className="lg:p-8 p-4 mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                            <div className="flex flex-col">
                                <span className="text-center text-2xl italic lg:text-5xl text-white capitalize">
                                    Ice Cream Flavor (Sabores)
                                </span>
                            </div>
                            <SwiperPro serdata = {IceData} />
                        </div>
                    </div>
                </Fade>
                <div className="mx-auto pb-8 lg:pb-14 max-w-screen-xl">
                    <div className=" flex flex-col space-y-4 lg:space-y-6">
                        <span className = "text-center text-xl lg:text-2xl text-main-color/40">Gallery</span>
                        <span className = "text-center text-3xl lg:text-5xl capitalize text-main-color">mira nuestra comida</span>
                        <div className="grid lg:grid-cols-4 gap-5 md:grid-cols-2 justify-items-center p-4 cursor-pointer">
                            {fnames.slice(0,7).map((fname , index) =>(
                                <div key={index} className="shadow-lg overflow-hidden rounded-lg">
                                    <img className="w-full ease-in-out transition-all duration-1000 hover:scale-125" src={fname} alt={fname}/>
                                </div>
                            ))}
                        </div>
                        <div className=" flex justify-center">
                            <button onClick={() => {
                                window.location.href = '/Gallery';
                            }} className=" py-3 transition ease-in-out hover:bg-white/20 duration-200 delay-100 bg-main-color w-[250px]">
                                <span className=" transition ease-in-out duration-200 delay-100 text-xl text-white">
                                    View More
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mx-auto pb-8 lg:pb-14 max-w-screen-xl">
                    <div className=" flex p-2 flex-col space-y-8 lg:space-y-8">
                        <span className = "text-center text-xl lg:text-2xl text-main-color/40">Location (Near Buy & Bye Market)</span>
                        <span className = "text-center text-3xl lg:text-5xl capitalize text-main-color">Nuestra Ubicacion</span>
                        <div className="lg:grid-cols-2 grid gap-4">
                            <div className=" hidden lg:block">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3174.1117879260687!2d-120.48167262434842!3d37.29248433979564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809143d7b128f917%3A0x7cbd448cd06a91ef!2sChurros%20%26%20Tacos%20El%20Cachorro!5e0!3m2!1sen!2sus!4v1711595143363!5m2!1sen!2sus" className=" w-full h-[500px] border-none" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 
                            </div>
                            <div className=" w-full group h-[500px] cursor-pointer">
                                <img className=" group-hover:opacity-50 h-full w-full" src= {store} />
                            </div>
                            <div className=" w-full lg:hidden block group h-[500px] cursor-pointer">
                                <img className=" group-hover:opacity-50 h-full w-full" src= {store1} />
                            </div>
                            <div className=" w-full lg:hidden block group h-[500px] cursor-pointer">
                                <img className=" group-hover:opacity-50 h-full w-full" src= {store2} />
                            </div>
                            <div className=" w-full lg:hidden block group h-[500px] cursor-pointer">
                                <img className=" group-hover:opacity-50 h-full w-full" src= {gato} />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button onClick={() => {
                                window.open('https://maps.app.goo.gl/M9STh56DeAcNMQYS6' , '_blank');
                                }} className=" py-3 transition ease-in-out hover:bg-white/20 duration-200 delay-100 bg-main-color w-[250px]">
                                <span className=" transition ease-in-out duration-200 delay-100 text-xl text-white">
                                    Get Directions                                    
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};